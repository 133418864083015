<template>
    <content-view>
        <documents-view/>
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import DocumentsView from '@/components/document/list/Content'
import { metaTitle } from '@/mixins/meta_title'

export default {
    name: "Documents",
    mixins: [metaTitle],
    components: { ContentView, DocumentsView }
}
</script>

<style lang="scss" scoped>
</style>
