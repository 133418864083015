<template>
    <div class="doc-base__content doc-base__content_left">
        <div class="doc-base__article">
            <div class="doc-base__article-section doc-base__article-section--wrap">
                <a class="doc-base__back_mobile" href="javascript:void(0)" @click="previousRubric" v-show="rubric_name">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823">
                        <path
                            d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"></path>
                    </svg>
                    Назад
                </a>
                <!-- Заголовок -->
                <div class="doc-base__head-top">
                    <div class="doc-base__title">
                        <a class="doc-base__back-link" href="javascript:void(0)" v-show="rubric_name"
                           @click="previousRubric">
                            <i class="fa fa-angle-left"></i>
                        </a>
                        <span v-show="!rubric_name"> Документы </span>
                        <span v-show="rubric_name"> {{rubric_name}} </span>
                    </div>

                    <v-popover
                        v-if="is_editor"
                        popoverClass="header__main-add-popover"
                        class="header__main-add-wrapper"
                        placement="bottom-end"
                        offset="10"
                    >
                        <button type="button" class="header__main-add" title="Добавить документ или рубрику">+</button>
                        <div class="header__main-add-popover-inner" slot="popover">
                            <router-link to="/documents/add/">
                                <span class="header__main-add-popover-icon">
                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.833984 2C0.833984 0.895431 1.72941 0 2.83398 0H12.1673C13.2719 0 14.1673 0.895431 14.1673 2V14C14.1673 15.1046 13.2719 16 12.1673 16H2.83398C1.72941 16 0.833984 15.1046 0.833984 14V2ZM3.50068 9C3.50068 8.44771 3.9484 8 4.50068 8H10.5007C11.053 8 11.5007 8.44771 11.5007 9C11.5007 9.55229 11.053 10 10.5007 10H4.50068C3.9484 10 3.50068 9.55229 3.50068 9ZM4.50068 4C3.9484 4 3.50068 4.44771 3.50068 5C3.50068 5.55228 3.9484 6 4.50068 6H7.83401C8.3863 6 8.83401 5.55228 8.83401 5C8.83401 4.44771 8.3863 4 7.83401 4H4.50068Z" fill="#989CAE"></path>
                                    </svg>
                                </span>
                                Документ
                            </router-link>
                            <a v-if="current_user.is_admin" href="javascript:void(0)" @click="addRubric">
                                <span class="header__main-add-popover-icon">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path d="M3.73343 6.26667C3.66569 6.26667 3.60569 6.31253 3.58809 6.37787L0.533425 14V14.2667C0.533425 14.4624 0.520892 14.5333 0.684092 14.5333H12.6198C12.9281 14.5333 13.1996 14.3256 13.2732 14.0499L16.0001 6.53333C16.0001 6.53333 16.0001 6.36667 16.0001 6.26667H3.73343Z" fill="#989CAE"></path>
                                            <path d="M3.39493 5.73333H14.1333H14.4V4.06187C14.4 3.66 14.0731 3.33333 13.6715 3.33333H7.07067L5.73733 1.46667H0.728533C0.326933 1.46667 0 1.7936 0 2.1952V13.3408L2.74187 6.21627C2.8152 5.94107 3.08667 5.73333 3.39493 5.73333Z" fill="#989CAE"></path>
                                        </g>
                                    </svg>
                                </span>
                                Рубрика
                            </a>
                        </div>
                    </v-popover>
                </div>
                <!-- Фильтр -->
                <!--<div class="doc-base__filter" v-show="rubrics.length !== 0 || documents.length !== 0 || search">
                    &lt;!&ndash; Поиск &ndash;&gt;
                    <div class="doc-base__search doc-base__search&#45;&#45;mobile" name="search-article">
                        <div class="input-group">
                            <input type="text" class="doc-base__search-input form-control" placeholder="Поиск"
                                   id="id_search" name="search" v-model="search">
                            <button type="submit" class="doc-base__search-btn btn fa fa-search"
                                    @click="searchData"></button>
                        </div>
                    </div>
                </div>-->
                <div class="header__search" v-show="rubrics.length !== 0 || documents.length !== 0 || search">
                    <input v-model="search" type="text" class="header__search-input" placeholder="Поиск">
                </div>
            </div>
            <!-- Пустая рубрика -->
            <div class="doc-base__article-section"
                 v-if="(rubrics.length === 0 && documents.length === 0 && !search) && (is_editor)"
                 v-show="!isLoading">
                <router-link to="/documents/add/" class="doc-base__add-material">
                    <span class="add-material__link">+ Добавить документ</span>
                </router-link>
            </div>

            <!-- Поиск не дал результатов-->
            <div class="doc-base__empty-block" v-if="(search && rubrics.length === 0 && documents.length === 0) ||
                    (rubrics.length === 0 && documents.length === 0 && !is_editor)"
                 v-show="!isLoading">
                <!--                    doc-base__block-content-->
                <div class="doc-base__empty-block--content">
                    <svg class="content__icon" width="112" height="112" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M56 112c30.928 0 56-25.072 56-56S86.928 0 56 0 0 25.072 0 56s25.072 56 56 56z"
                              fill="#FFF9EC"/>
                        <path d="M61.494 64.714l1.378 1.378 1.611 1.603.621.626 3.215-3.218-3.604-3.61-3.22 3.22z"
                              fill="#5D6D7E"/>
                        <path
                            d="M71.598 65.082l11.094 11.094a4.577 4.577 0 011.349 3.258 4.595 4.595 0 01-4.607 4.607 4.591 4.591 0 01-3.258-1.347L65.082 71.599l6.516-6.517z"
                            fill="#FF8E31"/>
                        <path
                            d="M70.067 66.615l.901-.903 11.096 11.095a3.687 3.687 0 011.085 2.626c0 .995-.386 1.93-1.085 2.632a.643.643 0 01-.906-.002.64.64 0 010-.9 2.43 2.43 0 00.716-1.731 2.411 2.411 0 00-.713-1.722L70.067 66.615z"
                            fill="#FECE59"/>
                        <path
                            d="M65.082 71.599l6.516-6.517-.486-.487a1.598 1.598 0 00-1.14-.474c-.45 0-.851.178-1.142.471l-4.237 4.24a1.599 1.599 0 00-.472 1.14c0 .442.181.85.472 1.136l.489.49zM27.959 48.788c0-11.502 9.326-20.83 20.83-20.83 11.505 0 20.83 9.329 20.83 20.83 0 11.505-9.326 20.83-20.83 20.834-11.504-.003-20.83-9.329-20.83-20.834z"
                            fill="#34495E"/>
                        <path
                            d="M48.789 67.365c10.256 0 18.575-8.316 18.575-18.577 0-10.255-8.319-18.574-18.575-18.574-10.258 0-18.576 8.318-18.576 18.574 0 10.26 8.318 18.577 18.576 18.577z"
                            fill="#5D6D7E"/>
                        <path
                            d="M48.789 65.878c9.436 0 17.088-7.65 17.088-17.09 0-9.434-7.652-17.088-17.088-17.088S31.7 39.354 31.7 48.788c0 9.44 7.651 17.09 17.089 17.09z"
                            fill="#CEE2F2"/>
                        <path
                            d="M49.239 34.506a1.514 1.514 0 00-1.342-.671 14.868 14.868 0 00-6.625 1.99 1.515 1.515 0 00-.546 2.065 1.507 1.507 0 002.06.541 11.942 11.942 0 015.288-1.584 1.497 1.497 0 001.414-1.586 1.428 1.428 0 00-.25-.755zM39.563 39.46a1.5 1.5 0 00-2.418-.107 14.923 14.923 0 00-3.067 6.57c-.553 2.866-.264 5.8.835 8.49a1.509 1.509 0 001.971.822 1.503 1.503 0 00.823-1.965 11.866 11.866 0 01-.659-6.774 11.792 11.792 0 012.438-5.244 1.508 1.508 0 00.077-1.792z"
                            fill="#fff"/>
                    </svg>
                    <span class="content__text"
                          v-show="rubrics.length === 0 && documents.length === 0 && search && rubric_id">
                        Поиск по рубрике не дал результатов. Попробуйте выполнить
                        <a href="javascript:void(0)" @click="loadHome">поиск</a> на главной странице Документов
                    </span>
                    <span class="content__text"
                          v-show="rubrics.length === 0 && documents.length === 0 && search && !rubric_id">
                        По вашему запросу ничего не найдено
                    </span>
                    <span class="content__text"
                          v-show="rubrics.length === 0 && documents.length === 0 && !search && !rubric_id"
                          v-if="!is_editor">
                        Раздел документов пуст
                    </span>
                </div>
            </div>
            <div v-if="rubrics.length > 0" id="result_list_container">
                <div class="doc-base__list" v-for="(item) in rubrics"
                     :key="'item_' + item.id">
                    <div class="doc-base__list-item" v-if="item.is_rubric">
                        <!-- Add class  doc-base__list-item--not-rubric  -->
                        <div class="doc-base__item-caption">
                            <span class="item-caption__text" style="cursor: pointer;" @click="loadRubric(item.id)">{{item.name}}</span>
                            <v-popover
                                v-if="item.is_access_edit"
                                popoverInnerClass="one-rubric__popover-inner"
                                popoverClass="one-rubric__popover"
                                placement="bottom-center"
                                offset="1"
                            >
                                <span class="item-caption__more">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4">
                                        <path fill-rule="evenodd"
                                              d="M4 2a2 2 0 10-4 0 2 2 0 004 0zM11 2a2 2 0 10-4 0 2 2 0 004 0zM18 2a2 2 0 10-4 0 2 2 0 004 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </span>
                                <div class="menu-btn__inner" slot="popover">
                                    <a href="javascript:void(0)" @click="editRubric(item)"
                                       class="menu-btn__button one-rubric__change"
                                       v-close-popover>Редактировать</a>
                                    <button class="menu-btn__button one-rubric__delete" @click="modalDeleteRubric(item)"
                                            v-close-popover>Удалить
                                    </button>
                                </div>
                            </v-popover>
                        </div>
                        <div class="doc-base__article-section" v-if="item.documents.length > 0">
                            <list-document v-for="(document) in item.documents" :key="'document_' + document.id"
                                           :document="document"
                                           :is_editor="item.is_access_edit"
                                           @modalDeleteDocument="modalDeleteDocument"></list-document>
                        </div>
                        <button class="base-btn doc-base__rubric-more" @click="loadMore(item)"
                            v-if="item.count !== item.documents.length">Показать еще
                        </button>
                    </div>
                </div>
            </div>
            <div class="doc-base__list" v-show="documents.length > 0">
                  <div class="doc-base__list-item doc-base__list-item--not-rubric">
                      <div class="doc-base__article-section" v-for="(document) in documents"
                           :key="'document_' + document.id">
                          <list-document :document="document"
                                         :is_editor="is_editor"
                                         @modalDeleteDocument="modalDeleteDocument"></list-document>
                      </div>
                  </div>
            </div>
        </div>

        <mugen-scroll
            :handler="infiniteScrollHandler"
            :should-handle="!isLoading"
        />
        <loading-spinner
            v-if="isLoading"
            class="wall__spinner"
        />
<!--            <div id="actions_block" class="doc-base__actions" v-if="documents_active">-->
<!--                <span class="doc-base__actions-item actions-item&#45;&#45;unchecked">Снять выделенные </span>-->
<!--                <div class="doc-base__actions-item actions-item&#45;&#45;delete">-->
<!--                    <span class="actions-item&#45;&#45;delete-icon">-->
<!--                        ×-->
<!--                    </span>-->
<!--                    Удалить выделенные-->
<!--                </div>-->
<!--            </div>-->

    </div>
</template>

<script>
    import session from '@/api/session'
    import ListDocument from './ListDocument'
    import MugenScroll from 'vue-mugen-scroll'
    import LoadingSpinner from '../../LoadingSpinner'
    import { mapState } from 'vuex'
    import { eventBus } from '@/main'
    import debounce from '@/helpers/debounce'

    export default {
        name: 'List',
        components: {
            MugenScroll,
            LoadingSpinner,
            ListDocument
        },
        props: {
            load_rubric_id: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                checkbox: [],
                nextData: '/api/v1/document/',
                isLoading: false,
                deleteDocument: [],
                deleteRubric: [],
                level_back: null,
                rubric_name: null,
                rubric_id: null,
                search: '',
                results: []
            }
        },
        created: function() {
            if (this.load_rubric_id) {
                this.loadRubric(this.load_rubric_id)
            } else if (window.location.hash) {
                this.loadRubric(+window.location.hash.slice(1));
            } else {
                this.loadHome()
            }

            this.debouncedSearch = debounce(this.searchData, 500)
        },
        mounted() {
            window.addEventListener('rubric-open', (event) => {
                this.loadRubric(event.detail.rubric_id);
            });
        },
        computed: {
            is_editor() {
                return this.current_user.is_editor_rubric_docs || this.current_user.is_editor_document || this.current_user.is_admin
            },
            rubrics: function () {
                let list = this.results.filter(item => !Array.isArray(item));
                list.map(item => {
                    if (item.next === null) {
                        item.is_exists_more = item.count > 2;
                        item.next = '/api/v1/document/load_more/{ID}/'.replace('{ID}', item.id);
                    }
                    return item
                });
                list.map(rubric => {
                    let documents = rubric.documents.filter(item => !this.deleteDocument.some(elem => elem.id === item.id));
                    documents.map(item => {
                        item.active = false;
                        return item
                    });
                    rubric.documents = documents;
                    return rubric
                });
                return list.filter(item => !this.deleteRubric.some(elem => elem.id === item.id))
            },
            documents: function () {
                let documents = [],
                    array = this.results.filter(item => Array.isArray(item));
                array.forEach(item => {
                    documents.push(...item)
                });
                documents.map(item => {
                    item.active = false;
                    return item
                });
                return documents.filter(item => !this.deleteDocument.some(elem => elem.id === item.id))
            },
            ...mapState('default_data', [
                'current_user'
            ]),
        },
        watch: {
            load_rubric_id: {
                handler: function (val, oldVal) {
                    if (val === 0) {
                        this.loadHome()
                    } else {
                        this.loadRubric(val)
                    }
                },
                deep: true
            },
            search: {
                handler: function (value) {
                    if(value.length >= 3) {
                        this.debouncedSearch()
                    } else if(value.length === 0) {
                        this.debouncedSearch()
                    }
                },
                deep: true
            },
        },
        methods: {
            async editRubric(item) {
                const ChangeRubric = () => import(`../../rubric/ChangeRubric`);
                this.$modal.show(ChangeRubric, {
                    permissionType: 'rubric_document',
                    url_list: `/api/v1/rubric_document/`,
                    rubric_id: item.id
                }, {
                    name: 'ChangeRubric',
                    adaptive: true,
                    maxWidth: 740,
                    width: '100%',
                    height: 'auto'
                })
            },

            addRubric() {
                const ChangeRubric = () => import('@/components/rubric/ChangeRubric');
                this.$modal.show(ChangeRubric, {
                    url_list: `/api/v1/rubric_document/`,
                    permissionType: `rubric_document`
                }, {
                    name: 'ChangeRubric',
                    adaptive: true,
                    maxWidth: 740,
                    width: '100%',
                    height: 'auto'
                })
            },
            
            async searchData() {
                if (this.search && this.nextData && this.rubric_id) {
                    this.nextData = this.nextData + '?search=' + this.search + '&rubric=' + this.rubric_id;
                } else if (this.search && !this.nextData) {
                    this.nextData = '/api/v1/document/?search=' + this.search;
                } else if (!this.search) {
                    this.nextData = '/api/v1/document/';
                }
                this.isLoading = true;
                let data = await this.loadList();
                this.results = [];
                this.results.push(...data);
                this.isLoading = false;
            },
            async loadHome() {
                this.nextData = '/api/v1/document/';
                this.$emit('rubricID', 0);
                this.results = [];
                await this.infiniteScrollHandler(true);
            },
            async previousRubric() {
                this.nextData = '/api/v1/document/';
                if (this.level_back) {
                    this.nextData = '/api/v1/document/?rubric={ID}'.replace('{ID}', this.level_back);
                    this.$emit('rubricID', this.level_back);
                    eventBus.$emit('item-open-rubric-document', this.level_back);
                } else {
                    this.nextData = '/api/v1/document/';
                    this.$emit('rubricID', 0);
                    eventBus.$emit('item-open-rubric-document', 0);
                    if (window.location.hash) {
                        this.$router.push({ name: 'Documents', hash: '' });
                    }
                }
                this.results = [];
                await this.infiniteScrollHandler(true);
            },
            async loadRubric(rubric_id) {
                this.nextData = `/api/v1/document/?rubric=${rubric_id}`;
                this.$emit('rubricID', rubric_id);
                this.results = [];
                await this.infiniteScrollHandler(true);
                eventBus.$emit('item-open-rubric-document', rubric_id);
            },
            async infiniteScrollHandler(access=false) {
                if (this.results.length > 0 || access) {
                    if (!this.nextData) {
                        return false;
                    }
                    this.isLoading = true;
                    let data = await this.loadList();
                    this.results.push(...data);
                    this.isLoading = false;
                }
            },
            async loadMore(item) {
                let params = {
                    rubric_id: item.id
                };
                try {
                    let request = session.get(`/api/v1/document/load_documents/`, {params});
                    let response = await request;
                    item.documents = response.data;
                } catch (err) {
                    this.$swal({
                        title: 'Ошибка!',
                        text: 'При обработке запроса произошла ошибка на сервере',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1400,
                    });
                }
            },
            // async loadMore(item) {
            //     session
            //         .get(item.next)
            //         .then(response => {
            //             let documents = item.documents.concat(response.data.results);
            //             item.documents = [...new Set(documents.map(JSON.stringify))].map(JSON.parse);
            //             item.next = response.data.next;
            //             item.is_exists_more = !!item.next;
            //         })
            //         .catch((err) => {
            //             this.errorHandler(err);
            //         })
            // },
            async loadList() {
                let data, response;

                try {
                    const request = session.get(this.nextData);
                    response = await request;
                    data = response.data.results;
                    this.nextData = response.data.next;
                    this.level_back = response.data.level_back;
                    this.rubric_name = response.data.rubric_name;
                    this.rubric_id = response.data.rubric_id;
                    return data
                } catch (err) {
                    this.errorHandler(err);
                }
            },
            async modalDeleteDocument(document) {
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Удалить документ?',
                    text: document.name,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да, удалить!',
                    cancelButtonText: 'Отменить',
                }).then(value => {
                    if (value.value) {
                        let swal = this.$swal({
                            title: 'Удаление...',
                            icon: 'info',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                        });
                        // выполнение
                        session
                            .delete(`/api/v1/document/${document.id}/`)
                            .then(response => {
                                this.popDocument(document);
                                eventBus.$emit('load-documents-list');
                                swal.close();
                                this.$swal({
                                    title: 'Отлично!',
                                    text: 'Документ удален',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            })
                            .catch((err) => {
                                this.$swal({
                                    title: 'Ошибка!',
                                    text: 'При обработке запроса произошла ошибка на сервере',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                                this.errorHandler(err);
                            });
                    }
                }, reason => {
                    // отказ
                });
            },
            async modalDeleteRubric(rubric) {
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Удалить рубрику?',
                    text: rubric.name,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да, удалить!',
                    cancelButtonText: 'Отменить',
                }).then(value => {
                    if (value.value) {
                        let swal = this.$swal({
                            title: 'Удаление...',
                            icon: 'info',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                        });
                        // выполнение
                        session
                            .delete('/api/v1/rubric_document/{ID}/'.replace('{ID}', rubric.id))
                            .then(response => {
                                eventBus.$emit('load-documents-list');
                                this.popRubric(rubric);
                                swal.close();
                                this.$swal({
                                    title: 'Отлично!',
                                    text: 'Рубрика удалена',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            })
                            .catch(error => {
                                this.$swal({
                                    customClass: {
                                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                                    },
                                    title: 'Внимание!',
                                    text: error.response.data.message,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Да, удалить!',
                                    cancelButtonText: 'Отменить',
                                }).then(value => {
                                    if (value.value) {
                                        let swal = this.$swal({
                                            title: 'Удаление...',
                                            icon: 'info',
                                            showConfirmButton: false,
                                            allowOutsideClick: false,
                                            html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                                        });
                                        // выполнение
                                        session
                                            .delete('/api/v1/rubric_document/{ID}/?always_delete=1'.replace('{ID}', rubric.id))
                                            .then(response => {
                                                this.popRubric(rubric);
                                                swal.close();
                                                this.$swal({
                                                    title: 'Отлично!',
                                                    text: 'Рубрика удалена',
                                                    icon: 'success',
                                                    showConfirmButton: false,
                                                    timer: 1400,
                                                });
                                            })
                                            .catch((err) => {
                                                this.errorHandler(err);
                                            });
                                    }
                                }, reason => {
                                    // отказ
                                });
                            });
                    }
                }, reason => {
                    // отказ
                });
            },
            showRightBlock() {
                this.$emit('showRightBlock', true);
            },
            errorHandler(err) {
                this.$swal({
                    title: 'Ошибка!',
                    text: 'При обработке запроса произошла ошибка на сервере',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1400,
                });
                this.isLoading = false;
            },
            popDocument(document) {
                this.$emit('changeList', true);
                this.deleteDocument.push(document);
            },
            popRubric(rubric) {
                this.$emit('changeList', true);
                this.deleteRubric.push(rubric);
            }
        }
    }
</script>
<style lang="sass">
    @import "#sass/v-style"

    .trigger
        margin-left: 10px

    .one-rubric
        &__popover
            border-radius: 10px
            border: none !important
            box-shadow: 1px 1px 24px 4px rgba(38, 38, 38, 0.3)

    //#main-container
    //    background: #f0f2f5

    .header__main-add-popover
        .popover-inner
            padding: 0 !important
        .popover-arrow
            display: none !important
        &-inner
            display: flex
            flex-direction: column
            min-width: 215px
            a
                position: relative
                display: inline-block
                padding: 16px 16px 15px 51px
                border-bottom: 1px solid #D8D8D8
                font-weight: 600
                font-size: 15px
                line-height: 20px
                color: #000000
        &-icon
            position: absolute
            top: 0
            left: 0
            display: flex
            align-items: center
            justify-content: center
            width: 51px
            height: 100%

    .header__main
        &-add
            margin-left: 20px
            padding: 0
            display: flex
            justify-content: center
            height: 36px
            width: 36px
            font-size: 1.5rem
            font-weight: 300
            color: #FFF
            background: $gold
            border-radius: 50%
            border: none
            line-height: 1.3
            &-wrapper
                @media (max-width: 600px)
                    position: absolute
                    top: 20px
                    right: 10px
</style>
