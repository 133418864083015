<template>
    <div class="doc-base__rubric-item">
        <div class="doc-base__col-item doc-base__col-item--small hidden" v-if="is_editor">
            <label class="css-control css-control-sm css-control-secondary css-checkbox">
                <input type="checkbox" name="selected"
                       class="css-control-input js-show-actions">
                <span class="css-control-indicator"></span>
            </label>
        </div>
        <div class="doc-base__col-wrap doc-base__col-wrap--width">
            <div class="">
                <div class="doc-base__col-text doc-base__col-text--bold">
                    <a target="_blank" :href="document.link"
                       class="doc-base__col-text doc-base__col-text--link doc-base__col-text--bold">
                        <span v-html="document.name"></span>
                    </a>
                    <div class="document__date">{{document.date_change}}</div>
                </div>
            </div>

            <div class="doc-base__col-item doc-base__col-item--very-small" v-if="is_editor">
                <div class="doc-base__col-item-wrapper">
                    <router-link class="doc-base__col-button"
                       :to="'/documents/edit/' + document.id + '/'">
                        <svg class="doc-base__col-icon--pencil"
                             xmlns="http://www.w3.org/2000/svg"
                             width="10" height="10" fill="none">
                            <path fill-rule="evenodd"
                                  d="M9.796 1.176L8.89.264a.897.897 0 00-1.272 0l-.869.873 2.034 2.045 1.014-1.019a.7.7 0 000-.987zm-3.048-.028l2.034 2.045-5.679 5.694L1.07 6.843l5.678-5.695zM.286 9.993a.232.232 0 01-.28-.277l.513-2.32 2.034 2.045-2.267.552z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </router-link>
                    <span class="doc-base__col-button" @click="modalDeleteDocument(document)">
                        <svg class="doc-base__col-icon--delete"
                             xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                             fill="none">
                            <path stroke-linecap="round" stroke-width="2"
                                  d="M1 1l7.615 7.615M8.615 1L1 8.615"/>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ListDocument',
        props: {
            document: {
                type: Object,
                default: {}
            },
            is_editor: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {}
        },
        methods: {
            modalDeleteDocument(document) {
                this.$emit('modalDeleteDocument', document);
            }
        }
    }
</script>

<style scoped lang="sass">
    @import "#sass/v-style"

    .document__date
        font-size: 0.875rem
        color: #a4aebd
    .doc-base__col-text
        flex-direction: column
        align-items: flex-start
</style>
