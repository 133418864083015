<template>
    <div class="doc-base"
        v-bind:class="{'doc-base--user': is_editor_document_bool && is_editor_rubric_bool, 'doc-base--no-num': on_numbering === 'False'}">
        <list @changeList="reRenderTree"
              @rubricID="loadRubricTree"
              @showRightBlock="showRightBlock"
              :key="keyList"
              :is_editor_document="is_editor_document_bool" :is_editor_rubric="is_editor_rubric_bool"
              :load_rubric_id="rubric_id"></list>
    </div>
</template>

<script>
    import { uuid } from 'vue-uuid';
    import List from './List';
    import NavigationTreeRubric from '../../rubric/NavigationTreeRubric';
    import { mapState } from 'vuex';

    export default {
        name: 'ContentDocument',
        components: {
            List,
            NavigationTreeRubric
        },
        computed: {
            ...mapState('default_data', [
                'current_user',
            ])
        },
        props: {
            is_editor_document: {
                type: String,
                required: false,
                default: 'False'
            },
            is_editor_rubric: {
                type: String,
                required: false,
                default: 'False'
            },
            on_numbering: {
                type: String,
                default: ''
            }
        },
        mounted() {
            this.$root.$on('updateContent', (event) => {
                this.reRenderTree();
                this.reRenderList();
            });
        },
        data() {
            return {
                is_editor_document_bool: this.is_editor_document === 'True',
                is_editor_rubric_bool: this.is_editor_rubric === 'True',
                object_id: 0,
                rubric_id: 0,
                showRightComponent: false,
                key_tree: uuid.v1(),
                keyList: uuid.v1()
            }
        },
        methods: {
            showRightBlock(value) {
                this.showRightComponent = value;
            },
            reRenderTree(value) {
                window.dispatchEvent(new CustomEvent('rubric-update-document', {
                    detail: {
                        update: true
                    }
                }));
            },
            reRenderList(value) {
                this.keyList = uuid.v1();
            },
            loadRubricTree(value) {
                this.object_id = value;
            },
            loadLeftBlock(item_id) {
                this.rubric_id = item_id
            },
        }
    }
</script>

<style scoped>

</style>
