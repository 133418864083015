<template>
    <li class="rubric__item rubric__item--directory">
        <div class="rubric__item_meta" @click="openList(item)">
            <div class="rubric__item_meta__name" v-bind:class="{'js-active': item.active}">
                <i class="rubric__item_meta__name_icon"></i>
                <a href="javascript:void(0)" class="rubric__item_meta__name_title"
                   :rubric_id="item.id" :ref="'rubric_' + item.id" :title="item.title">{{ item.name }}</a>
            </div>
            <span class="rubric__item_meta__count">{{ item.count }}</span>
        </div>
        <ul class="rubric" v-if="item.list.length > 0 || item.children.length > 0" v-show="item.active">
            <recursion-rubric v-for="child in item.children" :item="child" :key="child.id"></recursion-rubric>

            <li class="rubric__item rubric__item--file" v-bind:class="{'rubric__item--active': obj.active}"
                v-if="item.list && item.list.length > 0" v-for="obj in item.list" :key="'item_' + obj.id">
                <div class="rubric__item_meta">
                    <div class="rubric__item_meta__name">
                        <i class="rubric__item_meta__name_icon"></i>
                        <a :href="obj.link" :ref="'item_' + obj.id" class="rubric__item_meta__name_title">{{ obj.title
                            }}</a>
                    </div>
                    <span class="rubric__item_meta__count"></span>
                </div>
            </li>
        </ul>
    </li>

</template>

<script>
    export default {
        name: 'RecursionRubric',
        props: {
            object_id: {
                type: String
            },
            item: {
                type: Object
            }
        },
        mounted() {
            this.item.list.forEach(item => {
                let element = this.$refs['item_' + item.id][0];
                if (element.offsetWidth < element.scrollWidth) {
                    element.title = item.title

                }
            });
            let item = this.$refs['rubric_' + this.item.id];
            if (item.offsetWidth < item.scrollWidth) {
                item.title = this.item.title
            }
        },
        methods: {
            openList(item) {
                item.active = !item.active;
                this.$emit('loadLeftBlock', item.active ? item.id : 0);
            },
            focus: function () {
                this.$refs.input.focus()
            }
        }
    }
</script>
